<template>
  <l-map
    style="height: 100vh; width: 100vw"
    :zoom="zoom"
    :center="center"
    ref="coverageMap"
    id="coverageMap"
  >
    <l-tilelayer-googlemutant
      :apikey="apikey"
      :options="mapOptions"
      ref="googleMapLayer"
    ></l-tilelayer-googlemutant>
    <l-geosearch :options="geosearchOptions" ></l-geosearch>
    <l-geo-json :geojson="coverageData.geojson" :options="coverageData.options"></l-geo-json>
    <l-locatecontrol></l-locatecontrol>
  </l-map>
</template>

<script>
import { latLng } from 'leaflet';
import { LMap, LGeoJson } from 'vue2-leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import Vue2LeafletLocatecontrol from 'vue2-leaflet-locatecontrol'
import { GoogleProvider } from 'leaflet-geosearch';
import LGeosearch from 'vue2-leaflet-geosearch';
import axios from 'axios';

export default {
  components: {
    LMap,
    LGeoJson,
    'l-tilelayer-googlemutant': Vue2LeafletGoogleMutant,
    'l-geosearch': LGeosearch,
    'l-locatecontrol': Vue2LeafletLocatecontrol,
  },
  mounted() {
    this.fetchCoverageData();
  },
  data() {
    let mapOptions = {
      type: 'hybrid',
    };
    let apikey = 'AIzaSyAJ9A4Ht_kjDXAPPYRuvmfGCCeja0vz6wc';
    const addressprovider = new GoogleProvider({
        params: {
            key: 'AIzaSyAJ9A4Ht_kjDXAPPYRuvmfGCCeja0vz6wc',
            region: 'za',
        }
    });
    return {
      apikey,
      mapOptions,
      zoom: 6,
      center: latLng(-28.4792625, 24.6727135),
      coverageData: {
        geojson: null,
        options: {
            onEachFeature: this.onEachFeature.bind(this),
            style: this.styleMap.bind(this),
        }
      },
      geosearchOptions: {
        provider: addressprovider,
        notFoundMessage: 'Sorry, that address could not be found.',
        marker: {
            draggable: true,
        },
        style: 'button',
      }
    };
  },
  methods: {
    async fetchCoverageData() {
      let config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTA3NCwiaWF0IjoxNjYyNDY5NDQzfQ.6_Tk1aVGDUWDnPRrZ0_QxDOiG_2lgNyZDJwOwH3bWXk',
        },
      };

      let data = {
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [19.78164048472579, -24.23736569139949],
              [14.62655886697523, -28.54562628885199],
              [16.49188469960452, -35.28921394648364],
              [32.93085671754367, -35.4801623856946],
              [33.4735074712826, -21.48339964488461],
              [19.78164048472579, -24.23736569139949],
            ],
          ],
        },
        layer_ids: [64],
        plan_ids: [179],
      };

      let vetroResponse = await axios
        .post(
          'https://fibermap.za.vetro.io/v2/features/intersection',
          data,
          config
        )
        .then((r) => r);

      this.coverageData.geojson = vetroResponse.data.result;
      //console.log(this.coverageData.geojson);
    },

    styleMap(feature) {
        const status = feature.properties.Status;
        let color = null;
        switch(status) {
            case 'Live':
                // Live Style
                color = '#4a79d9';
                break;
            case 'Pre-Order':
                // Pre-Order Style
                color = '#fcc732';
                break;
            case 'SMI':
                // SMI Style
                color = '#fe534b';
                break;
            default:
                color = '#dcdee1';
                break;
        }

        return { color: color };
    },

    onEachFeature(feature, layer) {
        if (feature.properties && feature.properties.Name) {
            layer.bindPopup(feature.properties.Name + " - " + feature.properties.Status);
            layer.on('mouseover', () => { layer.openPopup(); });
            layer.on('mouseout', () => { layer.closePopup(); });
        }
    }
  },
};
</script>

<style>
@import '~leaflet/dist/leaflet.css';
@import '~leaflet-geosearch/dist/geosearch.css';
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
html,
body {
  height: 100%;
  margin: 0;
}
</style>
